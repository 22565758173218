import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
} from '@mui/material';
import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom";
import Page1 from "./Page1";
import Animals from "./Patients/Animals";
import {Helmet} from "react-helmet";
import {HelmetProvider} from "react-helmet-async";

const App = () => {
    const [doctors, setDoctors] = useState([]);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const fetchDoctors = async () => {
            try {
                const response = await axios.get('https://jsonplaceholder.typicode.com/users');
                setDoctors(response.data);
            } catch (error) {
                console.error('doktor listesi yok:', error);
            }
        };

        const fetchComments = async () => {
            try {
                const response = await axios.get('https://jsonplaceholder.typicode.com/comments');
                setComments(response.data);
            } catch (error) {
                console.error('doktor listesi yok:', error);
            }
        };

        fetchDoctors().then(r => fetchDoctors());
        fetchComments().then(r => fetchComments());
    }, []);

    const Home = () => {
        const navigate = useNavigate();

        const handleClick = () => {
            navigate('/');
        };

        const handleClickAnimals = () => {
            navigate('/animals');
        };

        const handleClickHasta = () => {
            navigate('/hastalar');
        };

        return (
            <Container>
                <Helmet>
                    <title>
                        { `Doktor Bilgi Portalı - Doktorlar Alemi - ` + (comments.length ? comments[0].name : "") }
                    </title>
                    <meta
                        name="description"
                        content={ `Doktorlar   description - ` + (comments.length ?comments[0].body : "") }/>/>
                </Helmet>
                <Button variant="contained" color="primary" onClick={handleClick}>
                    Doktor
                </Button>
                <Button style={{left: 20}} variant="contained" color="secondary" onClick={handleClickAnimals}>
                    Hayvan
                </Button>
                <Button style={{left: 30}} variant="contained" color="warning"
                        onClick={handleClickHasta}>
                    Hasta
                </Button>
                <br/>
                <br/>
                <Container>
                    <Typography
                        variant="h2"
                        component="h1"
                        gutterBottom>
                        Doktor
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Adı</TableCell>
                                    <TableCell>Telefon</TableCell>
                                    <TableCell>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {doctors.map((doctor) => (
                                    <TableRow key={doctor.id}>
                                        <TableCell>{doctor.name}</TableCell>
                                        <TableCell>{doctor.phone}</TableCell>
                                        <TableCell>{doctor.email}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Container>
        );
    };

    return (
        <HelmetProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/hastalar" element={<Page1 doctors={doctors} comments={comments}/>}/>
                    <Route path="/animals" element={<Animals doctors={doctors} comments={comments}/>}/>
                </Routes>
            </Router>
        </HelmetProvider>
    );
};

export default App;
