import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button
} from '@mui/material';
import {Edit, Delete} from '@mui/icons-material';
import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

const Page1 = (params) => {
    console.log(params.doctors)
    console.log(params.comments)
    const [doctors2, setDoctors2] = useState([]);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };

    const handleClickAnimals = () => {
        navigate('/animals');
    };

    const handleClickHasta = () => {
        navigate('/hastalar');
    };


   /* useEffect(() => {
        const fetchDoctors = async () => {
            try {
                const response = await axios.get('https://jsonplaceholder.typicode.com/users');
                setDoctors(response.data);
            } catch (error) {
                console.error('hasta listesi yok:', error);
            }
        };

        fetchDoctors().then(r => fetchDoctors());
    }, []);*/

    return (
            <Container>
                <Helmet>
                    <title> { `Doktor Bilgi Portalı - İnsanlar  Alemi - ` + (params.comments.length ? params.comments[1].name : "") }</title>
                    <meta
                        name="description"
                        content={ `İnsanlar   description - ` + (params.comments.length ? params.comments[1].body : "") }/>
                </Helmet>
                <Button variant="contained" color="primary" onClick={handleClick}>
                    Doktor
                </Button>
                <Button style={{left: 20}} variant="contained" color="secondary" onClick={handleClickAnimals}>
                    Hayvan
                </Button>
                <Button style={{left: 30}} variant="contained" color="warning"
                        onClick={handleClickHasta}>
                    Hasta
                </Button>
                <br/>
                <br/>
                <Typography variant="h2" component="h1" gutterBottom>
                    Hasta
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Adı</TableCell>
                                <TableCell>Telefon</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {params.doctors.map((doctor) => (
                                <TableRow key={doctor.id}>
                                    <TableCell>{doctor.name}</TableCell>
                                    <TableCell>{doctor.phone}</TableCell>
                                    <TableCell>{doctor.email}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
    );
};

export default Page1;
